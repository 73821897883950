import React from 'react';
import styled from 'styled-components';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import { TextAndImage, ContentWithLeaf } from '@beelineloans/cx-library/src/components/layout/panels';
import Container from '@beelineloans/cx-library/src/components/layout/Container';
import { H2, H3, Paragraph } from '@beelineloans/cx-library/src/components/typography';
import Profile from '@beelineloans/cx-library/src/components/Profile';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import Welcome from '@beelineloans/cx-library/src/images/welcome.png';
import Cam from '@beelineloans/cx-library/src/images/staff/cam.png';
import Greg from '@beelineloans/cx-library/src/images/staff/greg.png';
import Jay from '@beelineloans/cx-library/src/images/staff/jay.png';
import Jess from '@beelineloans/cx-library/src/images/staff/jess.jpg';
import Nick from '@beelineloans/cx-library/src/images/staff/nick.jpg';
// import Peter from '@beelineloans/cx-library/src/images/staff/peter.png';
import MediaKit from '@beelineloans/cx-library/src/images/media-kit.png';
import Chris from '../../../shared/images/cmoe.jpg';
import { ButtonText } from '../../../shared/GatsbyButton';

const ParagraphStyled = styled(Paragraph)`
  padding-bottom: 50px;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    display: block;
    max-width: 600px;
    padding-bottom: 110px;
    margin: 0;
  }
`;

const ProfileStyled = styled(Profile)`
  flex-basis: 100%;
  padding-bottom: 30px;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    flex-basis: 50%;
    padding-bottom: 80px;

    &:nth-child(even) {
      padding-left: 15px;
    }

    &:nth-child(odd) {
      padding-right: 15px;
    }
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const Half = styled.div`
  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    width: 50%;
    margin: 0;
  }
`;

const TextAndImageMediaKit = styled(TextAndImage)`
  ${H2} {
    padding-bottom: 20px;
  }

  ${H3}:last-of-type {
    padding-top: 60px;
  }
`;

const About = () => (
  <NavOnlyTemplate
    headerProps={{
      hideButton: true
    }}
  >
    <SEO path={CONST.LINKS.MAIN.ABOUT} title="The shortest path to your new home" description="See how we've compressed the old, meandering mortgage journey into a Beeline." />
    <TextAndImage
      image={{
        src: Welcome,
        mobileWidth: '315px',
        desktopWidth: '445px'
      }}
      heading="What’s a Beeline? It’s the shortest path to more financial freedom through property."
    >
      <Paragraph>
        Someone’s biggest investment is often their home, so we consider everyone an investor who can be better off financially,&nbsp; whether they have one property or ten.
      </Paragraph>
      <Paragraph>The loans just help them get there.</Paragraph>
      <Paragraph>
        But it can be a power imbalance, where the ‘little guy’ can’t access the same loans the tycoons&nbsp; use and can feel small up against the 500 pound mortgage lender who
        holds all the power — and the money.
      </Paragraph>
      <Paragraph>
        So we’ve opened it up to everyone, transforming a meandering home loan journey into a Beeline — using a&nbsp; combination of tech, old-fashioned warmth and responsiveness.
      </Paragraph>
      <Paragraph>The result? We get the loan out of the way — and get you closer to your financial happy place, faster.</Paragraph>
    </TextAndImage>
    <ContentWithLeaf backgroundColor={Theme.colours.background.tan} light={false}>
      <>
        <H2>The core crew.</H2>
        <ParagraphStyled>
          The founding team shared a belief that home buying should be fun, not endured. So in early 2019 they got to work, and started making a Beeline.
        </ParagraphStyled>
        <Wrap>
          <ProfileStyled name="Nick Liuzza" image={Nick} title="CEO" />
          {/* <ProfileStyled
            name="Peter Gonzalez"
            image={Peter}
            title="President and CFO"
          /> */}
          <ProfileStyled name="Greg Ellis" image={Greg} title="Chief Brand Officer" />
          <ProfileStyled name="Jay Stockwell" image={Jay} title="Chief Marketing Officer" />
          <ProfileStyled name="Jess Kennedy" image={Jess} title="Chief Operating Officer" />
          <ProfileStyled name="Cameron Slabosz" image={Cam} title="Chief Technology Officer" />
          <ProfileStyled name="Chris Moe" image={Chris} title="Chief Financial Officer" />
        </Wrap>
      </>
    </ContentWithLeaf>
    <Container>
      <Half>
        <H2>Our mission.</H2>
        <Paragraph>
          It’s pretty simple — 10 years from now, we want you to be floating on a giant inflatable flamingo in the pool of your dream home, without a financial care in the world.
        </Paragraph>
        <Paragraph>Sure, it’s lofty but stay with us…</Paragraph>
        <Paragraph>We believe everyone who owns a property is an investor looking for more financial freedom.</Paragraph>
        <Paragraph>That’s why every home loan we do puts you in a better financial position.</Paragraph>
        <Paragraph>We remove the traditional loan BS and shorten the path to your financial happy place.</Paragraph>
      </Half>
    </Container>

    <TextAndImageMediaKit
      heading="Telling our story."
      backgroundColor={Theme.colours.background.pinkGradient}
      left
      image={{
        src: MediaKit,
        mobileWidth: '315px',
        desktopWidth: '445px'
      }}
    >
      <H3>Media kit.</H3>
      <Paragraph>Everything you need is right here — all packaged up in this media-friendly bundle of goodness.</Paragraph>
      <Paragraph>
        <ButtonText to={`${process.env.GATSBY_ASSETS}${CONST.LINKS.EXTERNAL.DOWNLOADS.MEDIA_KIT}`} selected>
          Download media kit
        </ButtonText>
      </Paragraph>

      <H3>Media contact.</H3>
      <Paragraph>
        For all media inquiries please contact Greg Ellis
        <br />
        <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.MEDIA}`} selected>
          {CONST.LINKS.EXTERNAL.EMAIL.MEDIA}
        </ButtonText>
      </Paragraph>
    </TextAndImageMediaKit>
  </NavOnlyTemplate>
);

About.propTypes = {};

About.defaultProps = {};

export default About;
